import React from "react";
import { useStore } from "src/store";

export const useUserData = () => {
  const [
    selectedGameUser,
    user,
    activityCompleted,
    fetchGameState,
    fetchGamesUsers,
    fetchReport,
    fetchContent,
    fetchAccount,
  ] = useStore((state) => [
    state.selectedGameUser,
    state.user,
    state.reports?.activityCompleted,
    state.fetchGameState,
    state.fetchGamesUsers,
    state.fetchReport,
    state.fetchContent,
    state.fetchAccount,
  ]);

  React.useEffect(() => {
    if (user?.id) fetchGamesUsers();
  }, [fetchGamesUsers, user?.id]);

  React.useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  React.useEffect(() => {
    if (selectedGameUser?.guid && user?.id) {
      fetchReport();
      fetchGameState();
    }
  }, [fetchReport, fetchGameState, selectedGameUser, user?.id]);

  React.useEffect(() => {
    if (activityCompleted.length > 0) fetchContent();
  }, [activityCompleted, fetchContent]);
};
