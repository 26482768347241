import moment from "moment";

export function formatDate(date: string, format = "MMM DD YYYY") {
  return moment(date).format(format);
}

export function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}
