import { Box, Container, AboutStarsCollected } from "src/components";
import { useStore } from "src/store";
import { GraphLineBar } from "../GraphLineBar";

import "./StarsCollected.scss";

export const StarsCollected = (): JSX.Element => {
  const [starReceived] = useStore((state) => [state.reports.starReceived]);

  return (
    <Container
      className="stars-collected text-center md:text-left"
      aboutcontent={<AboutStarsCollected />}
      contentstyle="mdLaptop:max-h-containerModal smMobile:max-h-smModal mobile:max-h-mobileModal tablet:max-h-tabletModal  laptop:overflow-y-auto laptop:overflow-x-hidden"
    >
      <h2 className="text-center md:text-left mb-6">
        stars collected and minutes played
      </h2>
      <div className="stars-collected_container gap-4">
        <Box className="flex flex-col items-center justify-center">
          <img src="icons/star.png" alt="" />
          <h1>{starReceived.length}</h1>
          <h6 className="mb-3 text-center">
            Stars collected for acing Word Games in the past
            <br />7 days
          </h6>
        </Box>
        <GraphLineBar />
      </div>
    </Container>
  );
};
