import { Container, AboutLatestEarnings } from "src/components";
import "./LatestEarnings.scss";
import { EarnedItems, EarnedSevenDays, GearCount } from "./components";

export const LatestEarnings = (): JSX.Element => (
  <Container className="total-earnings" aboutcontent={<AboutLatestEarnings />}>
    <h2 className="text-center md:text-left mb-6">latest earnings</h2>
    <div className="total-earnings_container gap-4">
      <EarnedSevenDays />
      <EarnedItems />
      <GearCount />
    </div>
  </Container>
);
