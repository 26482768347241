import { RefObject } from "react";
import { AboutWordsOfWeek, Container } from "src/components";
import { useStore } from "src/store";

import { ItemList } from "../ItemList";
import { TagHeaderList } from "../TagHeaderList";

export const WordsOfWeek = ({
  rowRef,
}: {
  rowRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const [content] = useStore((state) => [state.content]);

  const data = [
    ...(content.AssembleSyllables ?? []),
    ...(content.MatchCollocations ?? []),
    ...(content.MatchSentenceBlank ?? []),
    ...(content.PopQuiz ?? []),
  ];

  const containerRef = () => {
    const screenSize = window.innerWidth;

    if (screenSize <= 1023) return undefined;
    return rowRef;
  };

  return (
    <Container
      rowref={containerRef()}
      className="words-of-week flex flex-col gap-4"
      aboutcontent={<AboutWordsOfWeek />}
      contentstyle="lgMobile:max-h-userModal smMobile:max-h-userModal mobile:max-h-userModal laptop:overflow-y-auto laptop:overflow-x-hidden"
    >
      <h2>words in the bank</h2>
      <TagHeaderList icon="check" total={data.length}>
        <span>
          Total words <br /> earned
        </span>
      </TagHeaderList>
      <ItemList data={data} icon="check" isBox={true} />
    </Container>
  );
};
