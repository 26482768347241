import clsx from "clsx";
import { Box } from "src/components";
import { useStore } from "src/store";

export const GearCount = ({
  modal = false,
}: {
  modal?: boolean;
}): JSX.Element => {
  const [gameState] = useStore((state) => [state.gameState]);

  return (
    <Box
      className={clsx(
        "total-count items-center text-center flex-col justify-center flex",
        { "w-40 h-40": modal }
      )}
    >
      <h1 className={clsx({ "text-md": modal })}>{gameState.gearCount}</h1>
      <h6 className={clsx("mb-3", { "text-xs": modal })}>
        Lifetime Gear Items
      </h6>
    </Box>
  );
};
