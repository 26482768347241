import { ColorBox } from "../ColorBox";

export const AboutLatestEarnings = () => (
  <div className="mt-4 grid gap-2 lgMobile:gap-16 mobile:gap-16 smMobile:gap-16 mb-4 max-h-containerModal">
    <h5 className="mb-7 mobile:mb-12 smMobile:mb-10">
      Latest earnings, latest learnings
    </h5>
    <ColorBox
      color="bg-boxColors-100"
      type="widget"
      imageUrl="images/seven_days_modal.png"
      padding="px-2 py-2 lgMobile:pt-6 mobile:pt-3 smMobile:pt-6"
      iconSize="mdLaptop:h-42"
      text="This shows everything your child has earned in the past seven days. Stars
      are earned by completing Word Games, while Coins are a soft currency used
      for player motivation. Most Gear Items can be unlocked using a combination
      of Stars and Coins. Every Star, Coin, and Gear Item earned is proof of
      your child’s brilliant word-learning progress!"
    />
    <ColorBox
      color="bg-boxColors-200"
      type="widget"
      imageUrl="images/gear_modal.png"
      padding="mdLaptop:px-2 py-4 lgMobile:pt-8 mobile:pt-8 smMobile:pt-12"
      iconSize="mdLaptop:right-auto"
      text={
        <>
          <p className="text-modal">
            This shows how many Gear Items your child has unlocked so far. This
            is no small feat!
          </p>
          <p className="text-modal">
            The more words kids learn, the more they earn!
          </p>
        </>
      }
    />
    <ColorBox
      color="bg-boxColors-300"
      type="widget"
      imageUrl="images/items_modal.png"
      padding="px-2 py-3 lgMobile:pt-6 mobile:pt-6 smMobile:pt-8"
      iconSize="mdLaptop:right-auto"
      text="Here are all the awesome Gear Items and accessories earned in the past seven days. It took a lot of word learning to earn that Lowrider Scooter and Space Helmet!"
    />
  </div>
);
