import React from "react";
import clsx from "clsx";

import { AboutGamesPerformance, Box, Container } from "src/components";
import { DonutChart } from "../DonutChart";

import { useStore } from "src/store";

import { ContentWordsTypes, ContentKeyData } from "src/types";

import "./GamesPerformance.scss";

export const GamesPerformance = (): JSX.Element => {
  const [content] = useStore((state) => [state.content]);
  const [wordCategories, setWordCategories] = React.useState<
    ContentWordsTypes[]
  >([]);

  React.useEffect(() => {
    const activities = Object.keys(content) as ContentWordsTypes[];
    setWordCategories(activities);
  }, [content]);

  return (
    <Container
      contentstyle="max-h-containerModal overflow-y-auto overflow-x-hidden mb-5"
      aboutcontent={<AboutGamesPerformance />}
    >
      <h2 className="mb-8 text-center md:text-left">Word Game Activity</h2>
      <div className="games-played_container gap-4">
        <div className="games-played_container_played">
          <div className="text-center pb-4">
            <h6>
              Number of Word Games
              <br />
              played in the past 7 days
            </h6>
          </div>
          <div className="flex flex-col gap-2">
            {wordCategories.map((item, i) => {
              return (
                <Box
                  key={item}
                  className={clsx(
                    "flex flex-col flex-grow xl:flex-row items-center gap-3 p-2 md:p-3"
                  )}
                >
                  <img
                    className="icon-played-game w-12"
                    src={ContentKeyData[item].icon}
                    alt=""
                  />
                  <div className="flex flex-col gap-1">
                    <strong className="text-center xl:text-left">
                      {ContentKeyData[item].title}
                    </strong>
                    <p className="px-1 md:px-0 text-center xl:text-left">
                      {ContentKeyData[item].description}
                    </p>
                  </div>
                  {content[item] && (
                    <div className="donut-chart flex flex-grow justify-end">
                      <DonutChart
                        score={content[item].length}
                        total={content[item].length}
                        theme="light"
                      />
                    </div>
                  )}
                </Box>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};
