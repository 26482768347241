import React from "react";
import * as V from "victory";
import moment from "moment";

import { useStore } from "src/store";

import { Box, NoInfoGames } from "src/components";

import { GameEvent } from "src/types";

import colors from "src/assets/variables.scss";

import "./GraphLineBar.scss";
interface GraphLineBarProps {
  children?: React.ReactNode;
}

type Graph = { x: number; y: number }[];

const baseGraphNumber = [12, 9, 6, 3, 0];

export const GraphLineBar = (props: GraphLineBarProps): JSX.Element => {
  const [reports] = useStore((state) => [state.reports]);
  const [graphLine, setGraphLine] = React.useState<Graph>([]);
  const [graphBar, setGraphBar] = React.useState<Graph>([]);
  const [weekdays, setWeekdays] = React.useState<number[]>([]);
  const [timePlayedTicks, setTimePlayedTicks] =
    React.useState<number[]>(baseGraphNumber);
  const [starsCollectedTicks, setStarsCollectedTicks] =
    React.useState<number[]>(baseGraphNumber);

  const sortEvents = (events: GameEvent[]) => {
    const sortedEvents = events.sort(
      (a, b) => moment(a.eventDt).valueOf() - moment(b.eventDt).valueOf()
    );
    return sortedEvents;
  };

  const groupByDays = (events: GameEvent[]) => {
    const graphData: Graph = [];

    for (const event of events) {
      const weekDay = moment(event.eventDt).dayOfYear();

      const indexGraphDay = graphData.findIndex(
        (graphDay) => graphDay.x === weekDay
      );

      if (indexGraphDay >= 0) {
        graphData[indexGraphDay].y += 1;
      } else {
        graphData.push({ x: weekDay, y: 1 });
      }
    }

    // ? INFO: add proportion stars list between the biggest number and zero

    const maxValue = Math.max(...graphData.map((o) => o.y));

    const proportionTime = maxValue * 0.25;

    let currentValue = maxValue + proportionTime;

    const tickValues =
      maxValue > 12
        ? Array.from({ length: 4 }, () => {
            currentValue -= proportionTime;
            return Number(currentValue.toFixed());
          })
        : baseGraphNumber;

    tickValues.push(0);

    setStarsCollectedTicks(tickValues);

    return graphData;
  };

  const groupByMinutes = (events: GameEvent[]) => {
    const graphData: Graph = [];

    for (const event of events) {
      const weekDay = moment(event.eventDt).dayOfYear();

      const indexGraphDay = graphData.findIndex(
        (graphDay) => graphDay.x === weekDay
      );

      const ms = moment(event.data.endDt).diff(moment(event.data.startDt));
      const duration = moment.duration(ms);
      const minutes = duration.asMinutes();

      if (indexGraphDay >= 0) {
        graphData[indexGraphDay].y += minutes;
      } else {
        graphData.push({ x: weekDay, y: minutes });
      }
    }

    const maxValue = Math.max(...graphData.map((o) => o.y));

    // ? INFO: add proportion time list between the biggest hour and zero

    const initialTime = Number(
      (maxValue < 100
        ? maxValue
        : (Math.round(maxValue / 100) + 1) * 100
      ).toFixed()
    );

    const proportionTime = initialTime * 0.25;

    let currentTime = initialTime;

    const tickValues = Array.from({ length: 4 }, () => {
      if (initialTime === 0) return 0;
      currentTime -= proportionTime;
      return Number(currentTime.toFixed());
    });

    //? INFO: add the max value in initial y position graph

    tickValues.unshift(initialTime);
    setTimePlayedTicks(tickValues);

    return graphData;
  };

  const getEventWeekdays = React.useCallback(() => {
    const events = [...reports.starReceived, ...reports.timePlayed];
    const sortedEvents = sortEvents(events);
    const lastDay = sortedEvents[sortedEvents.length - 1];

    let lastSevenDays: number[] = [];
    let dayOfYear = moment(lastDay.eventDt).dayOfYear();
    Array.from({ length: 7 }, () => {
      lastSevenDays.push(dayOfYear);
      return (dayOfYear -= 1);
    });
    lastSevenDays.reverse();
    setWeekdays(lastSevenDays);
  }, [reports.starReceived, reports.timePlayed]);

  const computeStarEvents = React.useCallback(() => {
    const events = sortEvents(reports.starReceived);
    const grouped = groupByDays(events);
    setGraphBar(grouped);
  }, [reports.starReceived]);

  const computeTimePlayedEvents = React.useCallback(() => {
    const events = sortEvents(reports.timePlayed);
    const grouped = groupByMinutes(events);

    setGraphLine(grouped);
  }, [reports.timePlayed]);

  const getDayFormat = (day: number) => {
    const today = moment().dayOfYear();
    const diff = today - day;
    return moment().subtract({ days: diff }).format("ddd");
  };

  React.useEffect(() => {
    if (reports.starReceived.length || reports.timePlayed.length)
      getEventWeekdays();
  }, [
    getEventWeekdays,
    reports.starReceived.length,
    reports.timePlayed.length,
  ]);

  React.useEffect(() => {
    if (reports.starReceived.length) computeStarEvents();
  }, [computeStarEvents, reports.starReceived]);

  React.useEffect(() => {
    if (reports.timePlayed.length) computeTimePlayedEvents();
  }, [computeTimePlayedEvents, reports.timePlayed.length]);

  return (
    <Box className="graph-line-bar p-0 pb-4 flex flex-col justify-between">
      <div className="relative">
        <div className="flex justify-between p-4 pb-0 -mb-8  gap-2 z-20">
          <div className="type-box py-1 p-2 flex gap-2 items-center">
            <img className="w-8" src="icons/star.png" alt="" />
            <strong>Stars Earned</strong>
          </div>

          <div className="type-box py-1 p-2 flex gap-2 items-center">
            <strong>Minutes Played</strong>
            <img className="w-8" src="icons/clock.svg" alt="" />
          </div>
        </div>

        {!graphBar.length && !graphLine.length && (
          <div className="absolute w-full h-full flex items-center justify-center">
            <NoInfoGames />
          </div>
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <V.VictoryChart domainPadding={{ x: 30, y: 0 }} height={240}>
            <V.VictoryAxis
              tickValues={weekdays}
              tickFormat={weekdays.map((day) => getDayFormat(day))}
              style={{
                axis: {
                  opacity: 0,
                },
                tickLabels: {
                  opacity: 0,
                },
              }}
            />
            <V.VictoryAxis
              dependentAxis
              offsetX={435}
              tickValues={timePlayedTicks}
              style={{
                axis: { strokeWidth: 0 },
                tickLabels: {
                  fontWeight: 600,
                  fontFamily: "Gotham",
                },
              }}
            />
            <V.VictoryGroup data={graphLine}>
              <V.VictoryLine
                interpolation="monotoneX"
                style={{
                  data: { stroke: colors.themeDarkBlue, strokeWidth: 3 },
                }}
              />
              <V.VictoryScatter
                size={5}
                style={{
                  data: {
                    fill: colors.themeDarkBlue,
                  },
                }}
              />
            </V.VictoryGroup>
          </V.VictoryChart>
        </div>

        <V.VictoryChart domainPadding={{ x: 30, y: 0 }} height={240}>
          <svg>
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="0%" x2="50%" y2="100%">
                <stop stopColor={colors.themeLightOrange} />
                <stop offset="0.279167" stopColor={colors.themeLightYellow} />
                <stop offset="0.529167" stopColor={colors.themeYellow} />
                <stop offset="1" stopColor={colors.themeOrange} />
              </linearGradient>
            </defs>
          </svg>
          <V.VictoryAxis
            tickValues={weekdays}
            tickFormat={weekdays.map((day) => getDayFormat(day))}
            style={{
              axis: {
                strokeWidth: 3,
                stroke: colors.themeDarkBlue,
              },
              tickLabels: {
                fontWeight: 600,
              },
            }}
          />
          <V.VictoryAxis
            dependentAxis
            tickFormat={starsCollectedTicks}
            style={{
              axis: { stroke: "transparent" },
              tickLabels: {
                fontWeight: 600,
              },
            }}
          />

          <V.VictoryBar
            cornerRadius={{
              top: 3,
            }}
            data={graphBar}
            style={{
              data: {
                stroke: colors.themeDarkBlue,
                fill: "url(#gradient1)",
                strokeWidth: 3,
                width: 38,
              },
            }}
          />
        </V.VictoryChart>
      </div>
    </Box>
  );
};
