import clsx from "clsx";
import { PropsWithChildren, ReactNode, RefObject, createRef } from "react";
import { useClickOutside } from "../../hooks";
import { Portal } from "../Portal";
import { Box } from "../Structures";
import "./Modal.scss";

interface ModalProps {
  footer?: ReactNode;
  anchorRef: RefObject<HTMLDivElement> | undefined;
  contentStyle?: string;
  childrenStyle?: string;
  onClose: () => void;
  open: boolean;
}

export const Modal = ({
  children,
  footer,
  anchorRef,
  onClose,
  contentStyle,
  childrenStyle,
  open,
}: PropsWithChildren<ModalProps>) => {
  const modalRef = createRef<HTMLDivElement>();
  useClickOutside(modalRef, onClose);

  if (!open) return null;

  return (
    <Portal wrapperRef={anchorRef}>
      <div className="modal">
        <Box
          className="relative inline-flex flex-col py-0 pr-0"
          contentstyle={contentStyle}
        >
          <div>
            <button type="button" onClick={onClose}>
              <img
                className="absolute right-0 top-0 -mt-4 -mr-4 w-8"
                src="icons/close.svg"
                alt=""
              />
            </button>
            <div ref={modalRef}>
              <div
                className={clsx(
                  "max-h-modal px-4 pr-6 pt-2 mb-7 overflow-y-auto overflow-x-hidden",
                  childrenStyle
                )}
              >
                {children}
              </div>
              {footer && <div className="p-4">{footer}</div>}
            </div>
          </div>
        </Box>
      </div>
    </Portal>
  );
};
