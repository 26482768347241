import createStore from "zustand";

import {
  ContentData,
  ContentReport,
  ContentReportTypes,
  ContentWords,
  GameEvent,
  GameState,
  GameUser,
  Report,
  User,
} from "src/types";

import * as DEMO from "./demo";
import GameItemsAssets from "src/assets/gamedata.json";

import { API } from "../modules";
interface Store {
  reports: Report;
  gameState: GameState;
  gameUsers: GameUser[];
  content: ContentWords;
  selectedGameUser: GameUser;
  user: User;
  fetchReport: () => void;
  fetchGameState: () => void;
  fetchGamesUsers: () => void;
  fetchContent: () => void;
  setGameUser: (gameUser: GameUser) => void;
  fetchAccount: () => void;
  fetchAccessEmail: (email: string) => void;
  setModalOpen: () => void;
  userLoading: boolean;
  reportsLoading: boolean;
  gameStateLoading: boolean;
  contentLoading: boolean;
  gameUsersLoading: boolean;
  words: ContentData;
  modalOpen: boolean;
}

const initialState = {
  reports: {
    message: [],
    starReceived: [],
    timePlayed: [],
    sessionStarted: [],
    activityCompleted: [],
    itemReceived: [],
    achievementEarned: [],
    missionFinished: [],
    missionStarted: [],
    missionAborted: [],
    wordCompleted: [],
  } as Report,
  gameState: {
    starCount: 0,
    coinCount: 0,
    canCount: 0,
    gearCount: 0,
    wordsCompletedCount: 0,
    wordsInProgressCount: 0,
  } as GameState,
  content: {
    AssembleSyllables: [],
    MatchCollocations: [],
    MatchSentenceBlank: [],
    PopQuiz: [],
  } as ContentWords,
  gameUsers: [] as GameUser[],
  selectedGameUser: {} as GameUser,
  user: {} as User,
  userLoading: true,
  reportsLoading: true,
  gameStateLoading: true,
  contentLoading: true,
  gameUsersLoading: true,
  modalOpen: false,
  words: {} as ContentData,
};

const reportsTreatment = (data: GameEvent[]) => {
  const types = data.map((item) => item.type);
  const keys = types.filter((item, pos) => types.indexOf(item) === pos);
  let reports = {} as Report;
  for (const eachKey of keys) {
    const filtered = data.filter((event) => event.type === eachKey);
    reports[eachKey] = filtered;
  }
  if (reports?.itemReceived) {
    reports.itemReceived = reports.itemReceived.filter(
      (item) =>
        (item.data.item?.id as string) !== "" &&
        item.data.item?.id &&
        GameItemsAssets.items[item.data.item.id]?.icon
    );
  }
  return reports;
};

const contentTreatment = (data: GameEvent[]): ContentWords => {
  const types = data.map((item) => item.data?.activity) as ContentReportTypes[];
  const keys = types.filter((item, pos) => types.indexOf(item) === pos);

  let content = {
    AssembleSyllables: [],
    MatchCollocations: [],
    MatchSentenceBlank: [],
    MatchSynonyms: [],
    PopQuiz: [],
    PopQuizSynonym: [],
    PopQuizCollocation: []
  } as ContentReport;

  for (const eachKey of keys) {
    const filteredByActivity = data.filter(
      (event) => event.data?.activity === eachKey
    );
    const filtered = [
      ...new Map(
        filteredByActivity.map((item) => [item.data.wordId, item])
      ).values(),
    ];
    if (content[eachKey]) content[eachKey] = filtered;
  }

  content.MatchCollocations = [
    ...content.MatchCollocations,
    ...content.MatchSynonyms,
  ];

  return {
    AssembleSyllables: content.AssembleSyllables,
    MatchCollocations: content.MatchCollocations,
    MatchSentenceBlank: content.MatchSentenceBlank,
    PopQuiz: [...content.PopQuiz, ...content.PopQuizSynonym, ...content.PopQuizCollocation],
  };
};

export const useStore = createStore<Store>((set, get) => ({
  ...initialState,
  fetchAccount: async () => {
    try {
      set({ userLoading: true });
      const data = await API.getAccount();
      set({ user: data, userLoading: false });
    } catch (error: any) {
      const reports = reportsTreatment(DEMO.REPORTS);
      const content = contentTreatment(DEMO.REPORTS);
      set({
        userLoading: false,
        reportsLoading: false,
        gameStateLoading: false,
        contentLoading: false,
        gameUsersLoading: false,
        reports: { ...initialState.reports, ...reports },
        gameState: DEMO.GAME_STATE,
        gameUsers: DEMO.GAME_USERS,
        selectedGameUser: DEMO.GAME_USERS[0],
        content,
        words: DEMO.WORDS,
      });
      throw error.message;
    }
  },
  setGameUser: (user: GameUser) => {
    set({ selectedGameUser: user });
  },
  setModalOpen: () => {
    set({ modalOpen: !get().modalOpen });
  },
  fetchGamesUsers: async () => {
    try {
      set({ gameUsersLoading: true });
      const data = await API.getUserData();
      set({
        gameUsers: data,
        selectedGameUser: data[0],
        gameUsersLoading: false,
      });
    } catch (error: any) {
      set({ gameUsersLoading: false });
      throw error.message;
    }
  },
  fetchReport: async () => {
    try {
      set({ reportsLoading: true });
      const userId = get().selectedGameUser.guid;
      const data = await API.getReport(userId);
      const reports = reportsTreatment(data);
      set({
        reports: { ...initialState.reports, ...reports },
        reportsLoading: false,
      });
    } catch (error: any) {
      set({ reportsLoading: false });
      throw error.message;
    }
  },
  fetchGameState: async () => {
    try {
      set({ gameStateLoading: true });
      const userId = get().selectedGameUser.guid;
      const data = await API.getGameState(userId);
      set({ gameState: data, gameStateLoading: false });
    } catch (error: any) {
      set({ gameStateLoading: false });
      throw error.message;
    }
  },
  fetchContent: async () => {
    try {
      set({ contentLoading: true });
      const activityCompleted = get().reports.activityCompleted;
      const activities = contentTreatment(activityCompleted);

      const wordIds: string[] = activityCompleted.map(
        (activity) => activity.data.wordId ?? ""
      );

      const filteredWordIds = wordIds.filter(
        (item, pos) => wordIds.indexOf(item) === pos
      );

      const data = await API.getContent(filteredWordIds);

      const enumWords: ContentData = {};

      for (const eachKey of data) {
        enumWords[eachKey.id] = eachKey;
      }

      set({ content: activities, words: enumWords, contentLoading: false });
    } catch (error: any) {
      set({ contentLoading: false });
      throw error.message;
    }
  },
  fetchAccessEmail: async (email: string) => {
    try {
      const data = await API.getAccessEmail(email);
      return data;
    } catch (error: any) {
      throw error.message;
    }
  },
}));
