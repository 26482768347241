import clsx from "clsx";

interface ColorBoxProps {
  color: string;
  imageUrl?: string;
  iconSize?: string;
  padding?: string;
  widget?: JSX.Element;
  paddingTop?: string;
  mainStyle?: string;
  text: JSX.Element | string;
  type?: "icon" | "widget" | "donut";
}

export const ColorBox = ({
  widget,
  color,
  imageUrl,
  padding,
  text,
  mainStyle,
  paddingTop = "lgMobile:pt-8 mobile:pt-8 smMobile:pt-8",
  iconSize = "h-9 w-9 left-0",
  type = "icon",
}: ColorBoxProps): JSX.Element => (
  <div className={clsx("relative my-2 mx-1 w-full", mainStyle)}>
    <div
      className={clsx(
        "rounded-xl min-h-containerModal grid content-center",
        color,
        paddingTop,
        {
          "ml-3 mb-6 p-2 smMobile:ml-0": type === "widget",
          "mr-2 p-4": type === "icon" || type === "donut",
        }
      )}
    >
      {imageUrl ? (
        <img
          className={clsx("absolute", iconSize, {
            aboutIcon: type === "icon",
            aboutWidget: type === "widget",
          })}
          alt=""
          src={imageUrl}
        />
      ) : widget ? (
        <div
          className={clsx(
            "absolute aboutIcon lgMobile:-top-5 mobile:-top-5 smMobile:-top-5",
            iconSize
          )}
        >
          {widget}
        </div>
      ) : (
        ""
      )}
      <p
        className={clsx("text-modal", padding, {
          "ml-32 lgMobile:ml-6 mobile:ml-6 smMobile:ml-3": type === "widget",
          "ml-6 lgMobile:ml-2 mobile:ml-2 smMobile:ml-2":
            type === "icon" || type === "donut",
        })}
      >
        {text}
      </p>
    </div>
  </div>
);
