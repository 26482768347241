import clsx from "clsx";

import "./NoInfoGames.scss";

interface NoInfoGamesProps extends React.HTMLAttributes<HTMLDivElement> {}

export const NoInfoGames = (props: NoInfoGamesProps): JSX.Element => {
  const { className } = props;

  return (
    <div
      {...props}
      className={clsx(
        "no-info inline-block w-full h-full relative leading-4",
        className
      )}
    >
      <div className="center-block text-center">
        <img className="center-block" src="icons/ghost_alert.svg" alt="" />
        <span className="max-w-xs">
          Info is updated daily. No games were played in the previous 7 days
        </span>
      </div>
    </div>
  );
};
