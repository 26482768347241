import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { Content, GameEvent, GameState, GameUser, User } from "src/types";

const API_URL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
    : `api/${process.env.REACT_APP_API_VERSION}`;

class ApiService {
  async request(url: string, config?: AxiosRequestConfig<any>) {
    try {
      const response = await axios(url, {
        withCredentials: true,
        ...config,
      });
      return await response.data;
    } catch (error: any) {
      Sentry.captureException(error);

      throw error.message;
    }
  }

  async getAccount(): Promise<User> {
    return this.request(`${API_URL}/account`);
  }

  async getUserData(): Promise<GameUser[]> {
    return this.request(`${API_URL}/users`);
  }

  async getGameState(userId: string): Promise<GameState> {
    return this.request(`${API_URL}/jupiter/report/${userId}/game-state`);
  }

  async getReport(userId: string): Promise<GameEvent[]> {
    return this.request(`${API_URL}/jupiter/report/${userId}`, {
      params: {
        after: moment().subtract(7, "days").toISOString(),
        before: moment().toISOString(),
      },
    });
  }

  async getContent(wordsId: string[]): Promise<Content[]> {
    return this.request(`${API_URL}/jupiter/report/content`, {
      data: wordsId,
      method: "POST",
    });
  }

  async getAccessEmail(email: string): Promise<void> {
    return this.request(`${API_URL}/jupiter/report/access-email`, {
      headers: {
        email,
      },
    });
  }
}

export const API = new ApiService();
