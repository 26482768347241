import { createRef } from "react";
import clsx from "clsx";
import { HeaderNav, InitialBanner, PageContainer } from "src/components";
import { OpinionBanner } from "src/components/Structures/OpinionBanner";
import { useStore } from "src/store";

import {
  UserHeader,
  LatestEarnings,
  StarsCollected,
  GamesPerformance,
  WordsOfWeek,
} from "./components";

import "./HomePage.scss";

export const HomePage = (): JSX.Element => {
  const [
    userLoading,
    gameUsersLoading,
    reportsLoading,
    gameStateLoading,
    modalOpen,
  ] = useStore((state) => [
    state.userLoading,
    state.gameUsersLoading,
    state.reportsLoading,
    state.gameStateLoading,
    state.modalOpen,
  ]);

  const isLoading =
    userLoading || gameUsersLoading || reportsLoading || gameStateLoading;

  const modalAuthRef = createRef<HTMLDivElement>();
  const mainRef = createRef<HTMLDivElement>();

  return (
    <PageContainer loading={isLoading}>
      <div ref={modalAuthRef} className="relative">
        <InitialBanner />
        <HeaderNav modalAuthRef={modalAuthRef} />
        <UserHeader modalAuthRef={modalAuthRef} />
      </div>
      <main
        ref={mainRef}
        className="container p-4 md:p-0 my-4 md:my-16 mx-auto gap-2 md:gap-4"
      >
        <div className="earning-container">
          <LatestEarnings />
        </div>
        <div className="stars-collected-container">
          <StarsCollected />
        </div>
        <div className="game-performance-container">
          <GamesPerformance />
        </div>
        <div className="words-collected-container gap-2 md:gap-4">
          <WordsOfWeek rowRef={mainRef} />
          <OpinionBanner />
        </div>
      </main>
      <div className={clsx({ "show-overlay": modalOpen })} />
    </PageContainer>
  );
};
