import { PropsWithChildren } from "react";
import clsx from "clsx";
import "./Box.scss";

interface BoxProps {
  className?: string;
  contentstyle?: string;
  onClick?: () => void;
}

export const Box = (props: PropsWithChildren<BoxProps>): JSX.Element => {
  const { children, className, contentstyle } = props;
  return (
    <div {...props} className={clsx("card-box p-4 ", className, contentstyle)}>
      {children}
    </div>
  );
};
