import { RouteEntry } from "./core";
import { FaqPage, HomePage } from "./modules";

export const routerConfiguration: RouteEntry[] = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/faqs",
    component: FaqPage,
  },
];
