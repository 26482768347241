import { DonutChart } from "src/modules/Home/pages/HomePage/components";
import { useStore } from "src/store";
import { ColorBox } from "../ColorBox";

export const AboutGamesPerformance = () => {
  const [content] = useStore((state) => [state.content]);

  return (
    <div>
      <h5 className="mb-3">Showstopping performance this week!</h5>
      <p className="mb-4">
        About 20% of playing time is spent completing short Word Games that
        ensure the spaced repetition needed to master a word. Word Games are
        played in a sequence, from <strong>spelling</strong> and{" "}
        <strong>pronunciation</strong>, to <strong>meaning</strong>, to use{" "}
        <strong>in context</strong>.
      </p>
      <ColorBox
        color="bg-boxColors-100 mt-8"
        imageUrl="icons/star.png"
        text="Each game completed rewards your child with a Star. The Mrs Wordsmith proprietary algorithm optimises learning by using a sequence of exposures. This means that kids encounter a new word twice on day 1, then see it again on day 2, and then revisit it on days 4, 6, 9, 13, and 18."
      />
      <ColorBox
        color="bg-boxColors-200 text-circle mt-8"
        type="donut"
        padding="py-2"
        widget={
          <DonutChart
            score={content["AssembleSyllables"]?.length}
            total={content["AssembleSyllables"]?.length}
            theme="light"
          />
        }
        text={
          <>
            The <strong>green circle</strong> to the right of each Word Game
            shows the number of times it has been played in the past 7 days. You
            may be wondering why we&apos;re not sharing the words themselves.
            This is because a word is not actually mastered until a player has
            interacted with it eight times over the course of three weeks.
            Before this point, it isn’t safe to assume that a word is learned,
            and testing your child on it can be counterproductive. Once your
            child has mastered a word, it’ll appear in the{" "}
            <strong>Words in the Bank</strong> section.
          </>
        }
      />
      <div className="grid md:grid-cols gap-4 py-4 mb-4">
        <div>
          <h6 className="mb-2">Word Jumble</h6>
          <p className="mb-4">
            Learn the <b>spelling</b> and <b>pronunciation</b> of a word by
            placing its jumbled parts in the right order. Then see a
            kid-friendly definition that introduces the meaning of the word.
          </p>
          <video width="100%" controls>
            <source src="videos/construction.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <h6 className="mb-2">Synonyms or Word Pairs</h6>
          <p className="mb-4">
            Learn more about a word's <b>meaning</b> by selecting synonyms
            (words with similar meaning) or word pairs (words that can be used
            together with the main word).
          </p>
          <video width="100%" controls>
            <source src="videos/synonym.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <h6 className="mb-2">Words in Context</h6>
          <p className="mb-4">
            Learn to use <b>words in context</b> by choosing the right word to
            complete engaging sentences of different genres.
          </p>
          <video width="100%" controls>
            <source src="videos/sentence.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <h6 className="mb-2">Pop Quiz</h6>
          <p className="mb-4">
            After reaching the Daily Word Stars goal, <b>reinforce learning</b>{" "}
            by selecting synonyms or word pairs in a fast-paced quiz.
          </p>
          <video width="100%" controls>
            <source src="videos/quiz.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
