import {
  createRef,
  HTMLAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useState,
} from "react";
import clsx from "clsx";

import { Modal } from "src/components/Modal";

import "./Container.scss";
import { useStore } from "src/store";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  aboutcontent?: ReactNode;
  themeaboutbutton?: "light-button";
  rowref?: RefObject<HTMLDivElement>;
  contentstyle?: string;
}

export const Container = (props: ContainerProps): JSX.Element => {
  const { children, aboutcontent, themeaboutbutton, rowref, contentstyle } =
    props;
  const [setModalOpen] = useStore((state) => [state.setModalOpen]);
  const containerRef = createRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!open);
    setModalOpen();
  }, [open, setModalOpen]);

  return (
    <>
      <div
        {...props}
        ref={containerRef}
        className={clsx("about-button p-4 md:p-8 relative", props.className, {
          "md:pb-14 pb-14": !!aboutcontent,
        })}
      >
        {children}
        {!!aboutcontent && (
          <div className="absolute bottom-3 right-3">
            <button
              className={clsx("about", themeaboutbutton)}
              onClick={toggleModal}
            >
              About
            </button>
          </div>
        )}
      </div>
      <Modal
        open={open}
        contentStyle="max-w-largeModal"
        childrenStyle={contentstyle}
        anchorRef={rowref || containerRef}
        onClose={toggleModal}
      >
        {aboutcontent}
      </Modal>
    </>
  );
};
