import React from "react";
import clsx from "clsx";

import "./DonutChart.scss";

interface DonutChartProps extends React.HTMLAttributes<HTMLDivElement> {
  score: number;
  total: number;
  theme?: "dark" | "light";
}

export const DonutChart = (props: DonutChartProps): JSX.Element => {
  const { score, total, theme = "light", className } = props;

  const percentage = React.useMemo(
    () => ((score / total) * 100).toFixed(),
    [score, total]
  );

  return (
    <div className={clsx(`doughnut-chart p${percentage}`, theme, className)}>
      <span>
        <span>{score}</span>
      </span>
      <div className="slice">
        <div className="bar" />
        <div className="fill" />
      </div>
    </div>
  );
};
