import React, { createRef, useCallback, useState } from "react";
import clsx from "clsx";
import { Box, Modal, NoInfoGames } from "src/components";

import { useStore } from "src/store";

import { Content, GameEvent } from "src/types";

import "./ItemList.scss";

interface ItemListProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: "check" | "alert";
  isBox?: boolean;
  data: GameEvent[];
}

export const ItemList = (props: ItemListProps): JSX.Element => {
  const modalRef = createRef<HTMLDivElement>();
  const [words, setModalOpen] = useStore((state) => [
    state.words,
    state.setModalOpen,
  ]);
  const [selectedWord, setSelectedWord] = useState<GameEvent | undefined>();

  const { data, icon, isBox, className } = props;

  const playAudio = useCallback((url: string) => {
    const audio = new Audio(url);
    audio.play();
  }, []);

  const itemClick = useCallback(
    (event: GameEvent | undefined) => {
      setSelectedWord(event);
      setModalOpen();
    },
    [setModalOpen]
  );

  const currentWord = (word?: GameEvent) => {
    return word?.data?.wordId ? words[word.data.wordId] : ({} as Content);
  };

  const word = currentWord(selectedWord);

  return data?.length ? (
    <>
      <Box
        className={clsx(
          "item-list_container p-0",
          {
            check: !isBox && icon === "check",
            alert: !isBox && icon === "alert",
          },
          className
        )}
      >
        <div
          ref={modalRef}
          className="grid item-list_container_content gap-2 pb-2 min-h-full p-4"
        >
          {data.map((event, i) => {
            return (
              <div
                key={event.eventId}
                onClick={() => itemClick(event)}
                className={clsx(
                  "item-list flex items-center justify-between p-2",
                  {
                    check: isBox && icon === "check",
                    alert: isBox && icon === "alert",
                  }
                )}
              >
                <div className="flex items-center gap-2">
                  <img className="w-9" src="icons/red_word.png" alt="" />
                  <h6>{currentWord(event)?.word}</h6>
                </div>
                <img className="w-6 h-6" src={`icons/${icon}.svg`} alt={icon} />
              </div>
            );
          })}
        </div>
      </Box>
      <Modal
        anchorRef={modalRef}
        open={!!selectedWord}
        onClose={() => itemClick(undefined)}
      >
        <div className="inline-flex flex-col gap-2 pb-4">
          <div className="flex items-center gap-1">
            <img className="-ml-2 w-12" src="icons/red_word.png" alt="" />
            <div>
              <h6>{word?.word}</h6>
              <p className="text-xs">{word?.partOfSpeech}</p>
            </div>
          </div>
          {word?.definition && (
            <div>
              <strong className="text-xs">Definition</strong>
              <p className="-mt-1 text-sm">{word.definition}</p>
            </div>
          )}
          {word?.synonyms?.length > 0 && (
            <div>
              <strong className="text-xs">Synonymous</strong>
              <p className="-mt-1 text-sm">
                {word.synonyms.map(
                  (synonym, index) =>
                    `${synonym.word}${
                      index === word.synonyms.length - 1 ? "" : ", "
                    }`
                )}
              </p>
            </div>
          )}
          <div onClick={() => playAudio(word.audio[0].uri)}>
            <img src="icons/sound.png" alt="" />
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <Box
      className={clsx(
        "item-list h-64",
        {
          check: !isBox && icon === "check",
          alert: !isBox && icon === "alert",
        },
        className
      )}
    >
      <Box
        className={clsx(
          "h-full",
          {
            check: isBox && icon === "check",
            alert: isBox && icon === "alert",
          },
          className
        )}
      >
        <NoInfoGames />
      </Box>
    </Box>
  );
};
