import clsx from "clsx";
import { Box, Tag } from "src/components";
import { EarnableItem, ItemType } from "src/modules/Home/types";
import { useStore } from "src/store";
import { GameEvent } from "src/types";

const EarnedDays = ({
  item,
  modal = false,
}: {
  modal?: boolean;
  item: EarnableItem;
}) => {
  const isModal = !modal ? "" : `modal-`;

  return (
    <div
      className={clsx(
        `${isModal}last-week_item last p-2 flex items-center ${isModal}last-week_${item.type}`,
        {
          "gap-4": !modal,
          "gap-2": modal,
        }
      )}
    >
      <img src={`icons/${item.type}.png`} alt="" />
      <h6 className={clsx("-mb-1", { "text-xs": modal })}>{item.title}</h6>
      <span>
        <Tag>{item.total}</Tag>
      </span>
    </div>
  );
};
export const EarnedSevenDays = ({
  modal = false,
}: {
  modal?: boolean;
}): JSX.Element => {
  const [reports] = useStore((state) => [state.reports]);
  const items = reports.itemReceived.filter(
    (item) => item.data.item?.type === ItemType.Currency
  );

  const getEarnedCoins = () => {
    let totalCoins = 0;
    items.map(
      (item: GameEvent) =>
        (totalCoins = totalCoins + (item?.data?.totalAmount ?? 0))
    );

    return totalCoins;
  };

  const earnedCoins = getEarnedCoins();

  const earnedItems = reports.itemReceived.filter(
    (item) => item.data.item?.type !== ItemType.Currency
  );

  return (
    <Box
      className={clsx("flex flex-col gap-2", {
        "last-week": !modal,
        "h-40 w-40 modal-last-week": modal,
      })}
    >
      <h6
        className={clsx({
          "self-center text-center w-32 md:w-full md:text-left my-2": !modal,
        })}
      >
        Earned in the past 7 days
      </h6>
      <EarnedDays
        modal={modal}
        item={{
          title: "Stars",
          type: "star",
          total: reports.starReceived.length,
        }}
      />
      <EarnedDays
        modal={modal}
        item={{
          title: "Gear Items",
          type: "gear",
          total: earnedItems.length,
        }}
      />
      <EarnedDays
        modal={modal}
        item={{
          title: "Coins",
          type: "coin",
          total: earnedCoins,
        }}
      />
    </Box>
  );
};