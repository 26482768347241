import clsx from "clsx";

interface StoreButtonProps extends React.HTMLAttributes<HTMLDivElement> {}

export const StoreButtons = (props: StoreButtonProps): JSX.Element => (
  <div {...props} className={clsx("flex flex-row gap-2", props.className)}>
    <a target="_blank" href={process.env.REACT_APP_APP_STORE} rel="noreferrer">
      <img className="h-8" src="images/app_store_button.png" alt="" />
    </a>
    <a target="_blank" href={process.env.REACT_APP_PLAY_STORE} rel="noreferrer">
      <img className="h-8" src="images/google_play_button.png" alt="" />
    </a>
  </div>
);
