import GameItemsJSON from "src/assets/gamedata.json";

export interface GameEvent {
  eventId: string;
  eventDt: string;
  type: GameEventTypes;
  data: {
    id?: string;
    message?: string;
    subject?: string;
    sendDt?: string;
    startDt?: string;
    endDt?: string;
    interactionEventId?: string;
    wordId?: string;
    activity?: ContentReportTypes;
    totalAmount?: number;
    item?: {
      type: number;
      id: keyof typeof GameItemsJSON.items;
    };
  };
}

export interface GameState {
  currentCharacter: AvatarKey;
  starCount: number;
  coinCount: number;
  canCount: number;
  gearCount: number;
  wordsCompletedCount: number;
  wordsInProgressCount: number;
}

export interface Content {
  id: string;
  word: string;
  partOfSpeech: string;
  definition: string | null;
  lexileScore: number;
  synonyms: {
    word: string;
  }[];
  audio: {
    uri: string;
    locale: string;
  }[];
}

export interface User {
  id: number;
  email: string;
}

export interface GameUser {
  guid: string;
  name: string;
}

export type GameEventTypes =
  | "message"
  | "starReceived"
  | "timePlayed"
  | "sessionStarted"
  | "activityCompleted"
  | "itemReceived"
  | "achievementEarned"
  | "missionFinished"
  | "missionStarted"
  | "missionAborted"
  | "wordCompleted";

export type Report = { [key in GameEventTypes]: GameEvent[] };

export type ContentReportTypes =
  | "AssembleSyllables"
  | "MatchCollocations"
  | "MatchSentenceBlank"
  | "MatchSynonyms"
  | "PopQuiz"
  | "PopQuizSynonym"
  | "PopQuizCollocation";

export type ContentReport = { [key in ContentReportTypes]: GameEvent[] };

export type ContentData = { [x: string]: Content };

export type ContentWords = {
  AssembleSyllables: GameEvent[];
  MatchCollocations: GameEvent[];
  MatchSentenceBlank: GameEvent[];
  PopQuiz: GameEvent[];
};

export type ContentWordsTypes = keyof ContentWords;

export enum AvatarImage {
  "Character_Barksy" = "avatar/Character_Barksy.png",
  "Character_Monica" = "avatar/Character_Monica.png",
  "Character_Roxy" = "avatar/Character_Roxy.png",
  "Character_Smashtag" = "avatar/Character_Smashtag.png",
  "Character_Wolfgang" = "avatar/Character_Wolfgang.png",
}

export const ContentKeyData = {
  AssembleSyllables: {
    title: "Word Jumble",
    description:
      "Learn the spelling and pronunciation of a word by placing its jumbled parts in the right order. Then see a kid-friendly definition that introduces the meaning of the word.",
    icon: "icons/wordsCompleted.png",
  },
  MatchCollocations: {
    title: "Synonyms or Word Pairs",
    description:
      "Learn more about a word's meaning by selecting synonyms (words with similar meaning) or word pairs (words that can be used together with the main word).",
    icon: "icons/can.png",
  },
  MatchSentenceBlank: {
    title: "Words in Context",
    description:
      "Learn to use words in context by choosing the right word to complete engaging sentences of different genres.",
    icon: "icons/scissors.png",
  },
  MatchSynonyms: {
    title: "Word Hunt",
    description: "",
    icon: "icons/scissors.png",
  },
  PopQuiz: {
    title: "Pop Quiz",
    description:
      "Reinforce learning by selecting synonyms or word pairs in a fast-paced quiz.",
    icon: "icons/lamp.png",
  },
};

export type AvatarKey = keyof typeof AvatarImage;
