import clsx from "clsx";
import { Button, Container } from "src/components";

import "./OpinionBanner.scss";

interface OpinionBannerProps extends React.HTMLAttributes<HTMLDivElement> {}

export const OpinionBanner = (props: OpinionBannerProps): JSX.Element => {
  const { className } = props;

  return (
    <Container
      className={clsx(
        "opinion-banner inline-flex flex-col gap-6 text-center items-center flex-grow justify-center",
        className
      )}
    >
      <div className="opinion-banner_image">
        <img className="back" src="images/opinion_back.svg" alt="" />
        <img src="images/opinion_character.png" alt="" />
      </div>
      <h2>Your opinion is important to us</h2>
      <p>Help us improve the report page leaving a message </p>
      <Button as="a" href={`mailto:${process.env.REACT_APP_OPINION_EMAIL}`}>
        Send Message
      </Button>
    </Container>
  );
};
