import { ColorBox } from "../ColorBox";

export const AboutStarsCollected = () => (
  <div className="grid gap-2 pb-4 lgMobile:gap-6 mobile:gap-6 smMobile:gap-6 text-justify">
    <h5>Star learner!</h5>
    <ColorBox
      color="bg-boxColors-100"
      imageUrl="icons/star.png"
      text={
        <>
          <strong className="text-modal">Stars Earned</strong> shows your
          child&apos;s weekly learning progress. Stars are earned by completing
          different Word Games. Up to 24 stars are up for grabs every day, so
          push your child to play more to learn more!
        </>
      }
    />
    <ColorBox
      color="bg-boxColors-200"
      imageUrl="icons/clock.svg"
      text={
        <>
          <strong className="text-modal">Time Played</strong> shows how many
          minutes of Word Tag your child has played this week. We suggest kids
          play for 20 to 30 minutes daily to unlock their full potential and
          learn 3 new words per day! Remember, a word is only mastered when the
          player has interacted with it eight times, over three weeks. You can
          see all mastered words in the{" "}
          <strong className="text-modal">Words in the Bank</strong> section.
        </>
      }
    />
    <p className="mdLaptop:ml-11 tablet:ml-11 ml-1 text-modal">
      To get the most out of Word Tag, encourage your <br /> little player to
      fill the Daily Word Stars bar every day.
    </p>
    <ColorBox
      color="bg-boxColors-300 w-2/5 lgMobile:w-full mobile:w-full smMobile:w-full py-8 lgMobile:mt-8 mobile:mt-8 smMobile:mt-8"
      type="icon"
      paddingTop="lgMobile:pt-14 mobile:pt-10 smMobile:pt-8"
      mainStyle="flex justify-end"
      iconSize="w-3/5 right-24 lgMobile:-top-1 lgMobile:right-4 mobile:-top-1 mobile:right-8 smMobile:top-4 smMobile:right-4 mdLaptop:right-20 tablet:right-14"
      imageUrl="images/stars_modal.png"
      text={
        <>
          Once the bar is full, they can complete the Pop Quiz for more rewards.
          Then, they’re done with word-related tasks for the day! Incredible!
          Come back tomorrow to keep learning.
        </>
      }
    />
  </div>
);
