import clsx from "clsx";
import React, { createRef } from "react";
import {
  Box,
  Button,
  Collapsable,
  Container,
  HeaderNav,
  InitialBanner,
  PageContainer,
} from "src/components";
import { OpinionBanner } from "src/components/Structures/OpinionBanner";
import { useStore } from "src/store";

import "./FaqPage.scss";

import * as MOCKS from "./mocks";

export const FaqPage = (): JSX.Element => {
  const [categoryIndex, setCategoryIndex] = React.useState(0);
  const [questions, setQuestions] = React.useState(
    MOCKS.FREQUENTLY_ASKED_QUESTIONS[0].questions
  );
  const [searchText, setSearchText] = React.useState("");

  const modalAuthRef = createRef<HTMLDivElement>();

  const [userLoading, modalOpen] = useStore((state) => [
    state.userLoading,
    state.modalOpen,
  ]);

  const searchQuestions = React.useCallback(() => {
    if (!searchText)
      return setQuestions(
        MOCKS.FREQUENTLY_ASKED_QUESTIONS[categoryIndex].questions
      );
    setCategoryIndex(-1);
    let allQuestions = [];
    for (const listQuestion of MOCKS.FREQUENTLY_ASKED_QUESTIONS) {
      allQuestions.push(...listQuestion.questions);
    }
    const filteredQuestions = allQuestions.filter((question) =>
      question.title.includes(searchText)
    );
    setQuestions(filteredQuestions);
  }, [categoryIndex, searchText]);

  const selectCategory = React.useCallback((index: number) => {
    if (index === -1) {
      getAllQuestions();
    } else {
      setQuestions(MOCKS.FREQUENTLY_ASKED_QUESTIONS[index].questions);
    }
    setCategoryIndex(index);
  }, []);

  const getAllQuestions = () => {
    let questions = [];
    for (const category of MOCKS.FREQUENTLY_ASKED_QUESTIONS) {
      questions.push(...category.questions);
    }
    setCategoryIndex(-1);
    setQuestions(questions);
  };

  React.useEffect(() => {
    getAllQuestions();
  }, []);

  return (
    <PageContainer loading={userLoading}>
      <div ref={modalAuthRef} className="relative">
        <InitialBanner />
        <HeaderNav modalAuthRef={modalAuthRef} />
      </div>
      <div className="faq-container container p-4 md:p-2 my-4 md:my-16 mx-auto gap-2 md:gap-4">
        <Container className="faq-container_search p-8 flex flex-col gap-2 flex-row-4">
          <h2 className="pb-4">faq categories</h2>
          <div className="flex py-4 gap-2">
            <input
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Keywords"
              type="text"
              name=""
              id=""
            />
            <Button className="h-10" onClick={searchQuestions}>
              Search
            </Button>
          </div>
          <Box
            className={clsx(
              "faq-container_topic flex justify-between cursor-pointer",
              {
                "faq-item_active": categoryIndex === -1,
              }
            )}
            onClick={() => selectCategory(-1)}
          >
            All
          </Box>
          {MOCKS.FREQUENTLY_ASKED_QUESTIONS.map((category, index) => (
            <Box
              className={clsx(
                "faq-container_topic flex justify-between cursor-pointer",
                {
                  "faq-item_active": categoryIndex === index,
                }
              )}
              key={category.id}
              onClick={() => selectCategory(index)}
            >
              {category.title}
            </Box>
          ))}
        </Container>
        <Container className="faq-container_questions flex flex-col gap-2 flex-grow">
          {questions.length ? (
            questions.map((faq) => (
              <Collapsable key={faq.id} title={faq.title}>
                {faq.description}
              </Collapsable>
            ))
          ) : (
            <Box>
              <strong>No questions found...</strong>
            </Box>
          )}
        </Container>

        <OpinionBanner className="faq-container_opinion" />
      </div>
      <div className={clsx({ "show-overlay": modalOpen })} />
    </PageContainer>
  );
};
