import React from "react";
import clsx from "clsx";
import { Tag } from "src/components";

import "./TagHeaderList.scss";

interface TagHeaderListProps extends React.HTMLAttributes<HTMLDivElement> {
  total: number;
  icon: "check" | "alert";
  children: React.ReactNode;
}

export const TagHeaderList = (props: TagHeaderListProps): JSX.Element => {
  const { total, icon, children, className } = props;

  return (
    <div
      className={clsx(
        "header-tag-list flex flex-col items-center p-4 mt-8",
        icon,
        className
      )}
    >
      <Tag className="-mt-10" icon={icon}>
        <h1>{total}</h1>
      </Tag>
      <strong className="mt-2">{children}</strong>
    </div>
  );
};
